

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/meilleurs-avocats-saint-hyacinthe-protection-conjoints-fait.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const ContentPage8 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Les meilleurs avocats à Saint-Hyacinthe pour régler tous les types de litiges! - Soumissions Avocat"
        description="Protégez-vous de la meilleure façon en engageant un avocat à Saint-Hyacinthe ! Intentez une poursuite, préparez une mise en demeure, allez en cour des petites créances... Cet avocat spécialisé négociera pour vous et vous défendra pour que vous remportiez votre cause. Obtenez les meilleurs prix pour un avocat à Saint-Hyacinthe."
        image={LeadImage}>
        <h1>Les meilleurs avocats à Saint-Hyacinthe pour régler tous les types de litiges!</h1><p>Saviez-vous qu’il y avait près de 27 000 avocats dans l’ensemble de la province de Québec en 2019? Avec autant de professionnels du droit mis à votre disposition, vous croyez réellement qu’il n’y en a pas au moins un dans le lot qui saurait s’occuper de votre dossier à la hauteur de vos attentes? Nous sommes convaincus qu’avec la qualité des juristes en sol québécois, vous serez en mesure de dénicher la perle rare dans la région de Saint-Hyacinthe!</p>
<p><StaticImage alt="meilleurs-avocats-saint-hyacinthe-protection-conjoints-fait" src="../images/meilleurs-avocats-saint-hyacinthe-protection-conjoints-fait.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Que vous éprouviez un problème familial nécessitant une aide légale, un trouble de voisinage ou que vous ayez simplement besoin de quelques conseils juridiques, les avocats sont là pour vous aider! Voici donc les principaux services juridiques qui sont offerts à Saint-Hyacinthe!</p>
<h2>Conjoints de fait : comment un avocat peut-il vous protéger?</h2>
<p>Les couples mariés savent certainement que la loi prévoit des protections pour eux au niveau financier, et ce, de plusieurs façons. Notamment, le mariage, tout comme l’union civile, emporte la composition d’un patrimoine familial composé des principaux actifs du couple. À la dissolution de l’union, qui arrive soit par le divorce ou par le décès de l’un des conjoints, la valeur ce patrimoine est divisé en parts égales entre les deux conjoints.</p>
<p>Cela signifie que la valeur de la maison, des régimes de retraite procurés pendant le mariage, des meubles du ménage et des véhicules de la famille sera séparée également.  Vous aurez compris que cela implique que peu importe lequel des conjoints est véritablement propriétaire des biens mentionnés précédemment, l’autre aura droit à la moitié de la valeur. Ainsi, même si la maison appartenait exclusivement à l’épouse, l’époux recevra la moitié de la valeur de celle-ci.</p>
<p>D’ailleurs, les règles du mariage et du patrimoine familial sont des règles d’ordre public qui ont priorité sur les règles de la copropriété par indivision et celles de la succession aussi bien légale que testamentaire. Cela signifie qu’en matière de copropriété indivise entre deux personnes mariées, la convention d’indivision prévoyant la répartition des quotes-parts ne s’applique pas; chacun des conjoints a tout de même droit à la moitié de la valeur de la maison.</p>
<p>En matière testamentaire, indépendamment des héritiers mentionnés dans le testament, il faudra toujours liquider le patrimoine familial avant de léguer les biens à ces derniers. Notez bien un point important; toutes les dispositions précédentes ne s’appliquent pas aux conjoints de fait!</p>
<p>Effectivement, peu importe le nombre d’années passées avec une personne, si l’union n’était que de fait et officialisée d’aucune manière, chacun repart avec les biens qui lui appartiennent! Cela peut être vite fait bien fait pour les couples ayant fait vie commune pendant une courte durée, mais lorsque l’union a duré des dizaines d’années et que les biens de l’un se sont confondus avec les autres, cela peut devenir un véritable casse-tête.</p>
<p>Le principal souci, c’est de protéger le conjoint ayant contribué de façon non pécuniaire à la famille. En effet, la vie fait parfois en sorte que l’un des deux membres du couple rapporte plus d’argent, alors que l’autre contribue au couple par des efforts non quantifiables, mais tout aussi essentiels. C’est toujours cette dernière personne qui se retrouve sans le sou à la suite d’une séparation de conjoints de fait!</p>
<p>C’est la raison pour laquelle, si vous ne souhaitez pas vous marier, vous devriez engager un avocat pour <strong>faire rédiger une convention de vie commune</strong>. Celle-ci prévoira les rôles généraux de chacun dans l’administration des biens, mais surtout au niveau de la répartition des actifs en cas de séparation. Cela est surtout important, voire même essentiel lorsque vous et votre conjoint de fait êtes propriétaires d’une maison.</p>
<p><strong>Les avocats sont d’ailleurs spécialisés dans la rédaction de telles conventions et sont en mesure de prévoir des clauses adaptées à votre réalité qui prévoiront une répartition équitable des biens si la relation devait être rompue.</strong></p>
<h2>Quels sont les délais de prescription pour les poursuites civiles?</h2>
<p>Intenter une poursuite contre un autre individu est un droit fondamental prévu pour tout citoyen, sauf dans les cas spécifiquement limités par la loi.  En effet, l’écoulement du temps est l’une des façons de faire tomber le droit de poursuivre une autre personne. Le délai est-il toujours le même? Non, il dépend du type de recours envisagé! Voici les principaux délais prévus par la loi!</p>
<p><StaticImage alt="delais-prescription-poursuites-civiles-immobilier-dommages-corporels" src="../images/delais-prescription-poursuites-civiles-immobilier-dommages-corporels.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Recours pour vice caché.</strong> Un vice caché se définit comme une malfaçon empêchant l‘usage normal de l’habitation et qui cause une dégradation prématurée. Un tel problème requiert donc des correctifs immédiats très dispendieux qui visent à limiter les dégâts. Vous n’avez toutefois pas à défrayer la facture sans rien dire si vous êtes victime d’un vice caché; un recours est ouvert à vous, mais il vous faudra l’entreprendre dans les 3 ans suivant la constatation du vice ou la découverte de sa gravité lorsque celui-ci s’installera de façon progressive.</p>
<p><strong>Poursuite contre une municipalité.</strong> Non seulement vous faudra-t-il vous lever de bonne heure pour poursuivre avec succès une municipalité, il vous faudra aussi le faire à l’intérieur des 6 mois suivants la date des dommages subis. Ce délai est toutefois supplanté par le délai de 3 ans prévu par le code civil pour des dommages corporels. Ainsi, si vous poursuivez une municipalité pour de tels dommages, c’est le délai le plus long qui s’applique.</p>
<p><strong>Prescription d’un bien meuble vs. immeuble.</strong> Pour prescrire un bien, il faut en être possesseur pendant un certain temps. La loi prévoit toutefois des délais différents selon la nature du bien. Notamment, un bien meuble (qui se bouge) se prescrit après une possession de 3 ans, alors qu’un bien immeuble (ex : un terrain), se prescrit après un délai de possession de 10 ans. Ces deux délais sont en vérité le temps dont dispose le vrai propriétaire pour déloger ces possesseurs et ainsi reprendre son bien.</p>
<p><strong>Poursuite pour des dommages corporels. </strong>Le préjudice corporel en est un qui viole l’intégrité physique d’une personne et qui est causé par la faute d’une autre. Le délai prévu par la loi pour entamer une action est de 3 ans à partir du constat des dommages, et non pas à la date oà les dommages ont été infligés.</p>
<p><strong>Les poursuites reliées à un crime. </strong>Poursuivre au civil en dommages-intérêts pour un préjudice causé par un crime emporte un délai particulier. De tels recours sont particuliers et les délais peuvent changer selon des circonstances bien précises, mais c’est généralement un délai de <strong>10 ans</strong> que bénéficie la victime pour poursuivre et obtenir une compensation monétaire. Lorsqu’il s’agit d’un crime de nature sexuelle, c’est plutôt un délai de <strong>30 ans</strong> qui s’applique.</p>
<p><strong>Le plus tôt vous consultez un avoc</strong><strong>at pour recevoir des conseils juridiques, moins vous avez de chances de dépasser le délai de prescription. Cessez d’hésiter et contacter un avocat le plus tôt possible dans la région de Saint-Hyacinthe!</strong></p>
<h2>Qu’est-ce qu’une mise en demeure et ses effets juridiques?</h2>
<p>Une mise en demeure est en fait une lettre envoyée au défendeur lui reprochant certains faits et indiquant les exigences du demandeur quant à la façon de corriger la faute alléguée. C’est donc l’étape qui précède la poursuite et qui permet parfois d’éviter d’avoir à se rendre jusqu’en cour.</p>
<p><StaticImage alt="mise-en-demeure-effets-juridiques-avocats-saint-hyacinthe" src="../images/mise-en-demeure-effets-juridiques-avocats-saint-hyacinthe.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Cependant, la mise en demeure n’est pas obligatoire pour poursuivre quelqu’un, sauf dans des situations oà elle est expressément requise. C’est le cas notamment des annulations de vente d’immeuble lors desquelles l’acheteur doit obligatoirement mettre le vendeur en demeure. L’autre exception prévue par la loi rendant obligatoire la mise en demeure est lors d’une <strong>poursuite pour vice caché</strong> ou lors d’une poursuite contre une ville.</p>
<p>À quoi sert donc la mise en demeure? À potentiellement éviter que le litige ne dégénère, à régler le problème hors cour ou encore laisser la chance à l’autre partie de reconnaître ses torts. D’un côté plus pragmatique, la mise en demeure permet surtout <strong>d’éviter d’avoir à défrayer les frais de justice</strong> en cas de victoire. Les frais de justice sont payés par la partie qui perd la cause, mais si celle-ci n’a pas été mise en demeure préalablement, elle n’aura pas à assumer une telle charge.</p>
<p>De plus, la mise en demeure permet de stipuler le montant auquel vous vous croyez redevable! En prenant la peine d’inscrire ce montant dans une mise en demeure, vous pourrez ajouter les intérêts à ce calcul et ainsi empocher encore plus d’argent en gagnant votre cause!</p>
<p><strong>Il est tout à fait possible de rédiger vous-mêmes la lettre de mise en demeure. Cependant, comme il s’agit d’une lettre reprochant une violation de vos droits, il serait judicieux d’engager un avocat pour la rédiger! </strong></p>
<h2>Pouvez-vous poursuivre quelqu’un pour un accident d’automobile?</h2>
<p>Vous avez été victime d’un accident d’automobile et avez subi des dommages corporels? Vous songez poursuivre la personne qui a causé l’accident pour obtenir une compensation pour les souffrances vécues? Nous sommes désolés de vous apprendre que le système juridique québécois n’autorise pas de telles poursuites! En effet, depuis l’instauration du régime « no-fault » au début des années 1970, les poursuites pour des accidents d’automobile ne sont plus admissibles devant les tribunaux.</p>
<p>C’est plutôt la Société de l’assurance automobile qui se chargera de vous indemniser selon le montant qu’elle fixe. Comment sera fixée l’indemnité? En fonction de l’évaluation que la SAAQ fait de vos blessures et en se basant sur les différents comparatifs disponibles!</p>
<p><strong>Le même principe est-il vrai si la personne était en état d’ébriété? </strong>Absolument! Bien que cette personne sera certainement poursuivie au criminel et traduite en justice pour ce geste dangereux, il sera toujours impossible pour vous de déposer une poursuite civile pour obtenir des dommages. C’est vers la SAAQ que vous devrez vous tourner pour le préjudice corporel subi et vers votre assureur privé pour les dommages matériels qu’a subis votre véhicule.</p>
<p><strong>Est-ce qu’un avocat peut changer quelque chose à cette situation? </strong>Un avocat ne peut pas changer le système juridique québécois, mais il peut débattre avec votre compagnie d’assurances pour contester l’indemnité établie et vous obtenir un montant plus important. C’est en analysant votre contrat d’assurance que votre avocat tentera de vous obtenir une meilleure compensation.</p>
<h2>Auprès de quelle cour devez-vous déposer votre poursuite?</h2>
<p>Entamer une poursuite, ça implique de mettre en demeure l’autre partie, mais aussi de déposer une demande introductive d’instance auprès de l’une des cours du Québec. Le système de justice québécois fonctionne selon un amalgame de cours ayant chacune une compétence unique et exclusive. Il faut donc savoir à qui vous adresser avant de déposer une demande! Voici donc la compétence des différentes cours!</p>
<p><StaticImage alt="competence-cour-quebec-superieure-petites-creances-saint-hyacinthe" src="../images/competence-cour-quebec-superieure-petites-creances-saint-hyacinthe.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Cour du Québec :</strong> On dit de la Cour du Québec qu’elle est un tribunal de première instance et qu’elle peut agir pour des causes d’ordre civiles, mais également criminelles et pénales. En matière civile, sa compétence se limite aux litiges d’une valeur de <strong>moins de 85 000$.</strong> Elle possède aussi plusieurs chambres, dont une de la jeunesse chargée d’entendre des demandes d’adoption, d’émancipation ainsi que des dossiers impliquant l’intervention du Directeur de la protection de la jeunesse. De plus, la Cour du Québec est la seule compétence pour entendre les demandes de garde en établissement et d’autorisation de soins.</p>
<p><strong>Cour supérieure :</strong> On qualifie la Cour supérieure de tribunal de droit commun. Comme la Cour du Québec, celle-ci peut entendre des causes de droit criminel, tout comme de droit civil. Dans ce dernier cas, sa compétence sera engagée lorsque le litige en jeu aura une valeur de plus de 85 000$. Pour ce qui est des causes de droit criminel, ce sont <strong>les procès devant juges et jury</strong> qui devront absolument être entendus dans cette cour.</p>
<p><strong>
Cour des petites créances :</strong> La division des petites créances de la Chambre civile de la Cour du Québec entend des litiges d’une valeur inférieure à 15 000$. Par souci d’efficacité et pour éviter des frais extrajudiciaires disproportionnés par rapport au bénéfice espéré, il est interdit d’être représenté par un avocat à la Cour des petites créances; chacun des parties défend ses prétentions par lui-même!</p>
<p>Tribunal des droits de la personne : Le tribunal des droits de la personne est une entité visant à protéger les droits fondamentaux des citoyens canadiens. Il protège notamment de la discrimination, du harcèlement et des inégalités. Bref, ce tribunal se spécialise dans l’application de la Charte des droits et libertés de la personne.</p>
<p>Tout recours commence avec une demande introductive d’instance déposée auprès de la bonne cour ou du bon tribunal. Il est vrai qu’il n’est pas si simple de déterminer quelle instance est compétente à recevoir votre cause. C’est justement la raison pour laquelle vous devriez engager un avocat à Saint-Hyacinthe pour vous assister!</p>
<h2>Les avantages d’engager un avocat spécialisé!</h2>
<p>Les avocats sont habituellement regroupés en diverses spécialités dans lesquelles ils concentrent le plus clair de leurs efforts. Comme chaque secteur du droit a sa propre jurisprudence, ses propres nuances et son propre rythme d’évolution, ce n’est certes pas si surprenant d’apprendre que la pratique légale ne s’éparpille pas. Mais quels sont les avantages concrets à engager un avocat spécialiste au lieu d’un généraliste?</p>
<p><strong>Plus grande efficacité. </strong>Être avocat demande une quantité énorme de recherche autant au niveau des décisions antérieures similaires qu’en termes de preuves admissibles. Ce travail se complexifie nettement lorsque l’avocat que vous engagez n’a pas l’habitude de faire des recherches dans le domaine de droit pour lequel vous l’engagez. Il nécessitera plus de temps pour trouver des sources pertinentes, ce qui aura des répercussions sur la facture à défrayer!</p>
<p>Meilleur résultat. Non seulement sauvez-vous du temps, vous avez aussi de bien meilleures chances de remporter votre cause en engageant un spécialiste. Ce dernier est non seulement plus rapide au niveau de la construction du dossier, mais il est aussi mieux placé pour choisir les arguments qui auront un impact en cour et laisser les débats inutiles de côté.</p>
<p>Relation de confiance. Vous savez que votre avocat n’entreprend pas ce type de recours pour la première fois et qu’il sait ce qu’il fait. Vous n’avez donc pas à vous en faire et vous pouvez collaborer au dossier en étant certain qu’il est entre bonnes mains!</p>
<p><strong>Est-ce que cela signifie qu’il n’est jamais opportun d’engager un avocat généraliste?</strong> Pas du tout! Ces derniers sont plus que capables de prendre en charge les dossiers relatifs aux domaines de droit très commun, tels que les obligations, le droit du travail, de l’immobilier, etc. Leur expertise perd toutefois de l’aplomb lorsque le débat tourne autour d’un point très poussé dans un domaine de droit donné.</p>
<h2>Respect des normes du travail : les avantages de l’avocat à Saint-Hyacinthe</h2>
<p>Après des années d’abus dans le monde du travail où les employeurs tiraient avantage du dur labeur de leurs employés dans des conditions misérables, la Loi sur les normes du travail a vu le jour. Cette mesure législative a pour seul but de prévoir un seuil minimal de conditions de travail qu’un employeur est tenu de respecter. Bien qu’il soit permis à un employeur d’offrir de meilleures conditions, il ne peut légalement offrir moins.</p>
<p><StaticImage alt="avocat saint hyacinthe application loi normes travail" src="../images/avocat-saint-hyacinthe-application-loi-normes-travail-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Qu’arrive-t-il si votre employeur ne respecte pas vos droits en vertu de la Loi sur les normes du travail? Celui-ci s’expose à un recours de la part des employés lésés, mais également à des amendes imposées par la CNESST. Celles-ci ont de quoi refroidir les ardeurs des employeurs récalcitrants.</p>
<p><strong>Mais quels sont les principaux droits conférés par la Loi sur les normes du travail? </strong>Cette loi couvre les principaux droits des travailleurs en matière de salaire minimum, de durée des congés, des indemnités de vacances, de jours fériés et de congédiement illégal, entre autres! Voici donc les grandes lignes que tout travailleur devrait connaître!</p>
<p><strong>Salaire minimum : </strong>La Loi sur les normes du travail stipule clairement qu’il est interdit de rémunérer un salarié en déca du montant stipulé dans le règlement. Le salaire minimum est généralement ajusté assez fréquemment afin de tenir compte de l’inflation et du coût de la vie. Par contre, le droit d’être rémunéré selon le salaire minimum varie selon le type d’emploi.</p>
<p>C’est pourquoi la Loi sur les normes du travail fixe un salaire minimum différent pour les salariés qui travaillent « à pourboire », celui-ci étant inférieur au seuil des salariés ordinaires. De plus, certains emplois comme les stages étudiants ne sont pas visés par cette disposition, ce qui permet aux employeurs de payer à ces travailleurs un salaire inférieur.</p>
<p>Heures de travail : Le droit de ne pas effectuer davantage d’heures que requis est un droit qui doit être mitigé selon le type d’emploi. Pour les salariés de secteurs traditionnels, la semaine de travail est d’une durée de 40 heures. Cependant, plusieurs secteurs du marché ne sont pas visés par une telle disposition, comme les cadres d’une entreprise, les employés en temps de récolte annuelle, les gardiens de prison et autres!</p>
<p>Par contre, avec les heures supplémentaires viennent les indemnités applicables que l’employeur est tenu de payer. La loi prévoit une règle simple voulant que les heures supplémentaires soient payées à « temps et demi ». D’ailleurs, un employé est en droit de refuser de travailler plus de 2 heures de plus que ses heures normales de travail et plus de 50 heures par semaine.</p>
<p>Vacances et jours fériés : Tout travailleur a droit à des vacances, peu importe le type de travail accompli, à moins qu’il ne soit expressément visé par la loi. Pour les travailleurs justifiant moins de 3 ans de service continu, on parle d’une indemnité équivalant à 4% du salaire annuel (équivalant à 2 semaines), tandis que pour les employés présentant plus de 3 ans, on parle plutôt d’une indemnité équivalant à 6% du salaire annuel.</p>
<p>Pour ce qui est des jours fériés, la croyance populaire voulant que les salariés doivent être rémunérés « à temps double », soit être nuancée. La véritable indemnité prévue par la Loi est celle du droit de l’employé de recevoir le un vingtième du salaire qu’il a perçu dans les 30 derniers jours.</p>
<p>Pour un employé qui travaille à temps plein, 40 heures par semaine, cela signifie un jour complet d’indemnité. Si le travailleur est en congé lors du jour férié, il reçoit l’indemnité en guise de salaire, alors que s’il se présente au travail, il reçoit cette indemnité en plus de son salaire.</p>
<p>Délai de congé (préavis de départ): Le délai de congé est le préavis que le commun des mortels appelle « le deux semaines ». Il s’agit de l’avis donné par l’employé ou l’employeur lorsque l’un ou l’autre désire mettre un terme à la relation de travail. Ce que plusieurs ignorent, c’est que le délai approprié peut varier selon la durée de l’emploi et la nature de celui-ci.</p>
<p>En effet, un employé qui n’a été en poste qu’une semaine pourrait être justifié de donner un préavis plus court que deux semaines, tout comme l’employé qui a passé plusieurs décennies au même poste à titre de dirigeant devra donner un préavis plus long. D’ailleurs, donner un préavis déraisonnable et à contretemps peut justifier un recours en dommages contre l’employé qui part.</p>
<p><strong>
Harcèlement psychologique :</strong> Récemment ajouté à la Loi sur les normes du travail, le droit de l’employé à un milieu de travail exempt de toxicité est maintenant un principe légal fondamental. Les modifications a la loi ont d’ailleurs imposé un devoir de proactivité à l’employeur, signifiant que celui-ci ne peut pas prétendre ne pas avoir eu connaissance du harcèlement invoqué et il s’expose à des sanctions, même si le harcèlement ne provient pas directement de lui.</p>
<p>Absences justifiées : En temps normal, un employé ne peut pas s’absenter de son milieu de travail sans l’autorisation de son employeur.  Toutefois, la loi prévoit des circonstances où l’employé en question peut s’absenter sans avoir besoin d’autorisation et surtout sans craindre des représailles. Notamment, le décès d’un proche, les examens de grossesse, le mariage d’un proche et autre évènement important similaire sont tous visés par la loi.</p>
<p>Rappelez-vous que la Loi sur les normes du travail ne représente que le seuil minimal des conditions de travail auxquelles vous avez le droit! Si votre employeur ne vous offre même pas le minimum, pensez à consulter un avocat spécialisé en droit du travail pour faire respecter vos droits!</p>
<h2>Est-ce que la Loi sur les normes du travail s’applique à vous?</h2>
<p>Nous avons mentionné que certains types de travailleurs étaient exclus de l’application de la Loi sur les normes du travail. Avant de songer intenter un recours, vérifier si vous êtes visés serait une première étape fort judicieuse. Qui sont donc les travailleurs exclus de la loi?</p>
<p>D’abord, les travailleurs autonomes ne peuvent pas se prévaloir de la Loi sur les normes du travail, car comme ils sont les propres maîtres de leurs conditions de travail, les dispositions de la loi n’auraient aucun sens.</p>
<p>Ensuite, les étudiants occupant certains emplois comme des stages ou encore des postes d’animateur dans ce que la loi appelle des « colonies de vacances », ne peuvent pas non plus invoquer les dispositions de la loi concernant le salaire minimum, mais sont tout de même couverts par les autres dispositions de cette même loi.</p>
<p>Certains employés sont cependant complètement exclus. Comme la Loi sur les normes du travail est une loi provinciale, elle ne s’applique pas aux entreprises fédérales, qui elles, sont assujetties à une loi qui leur est propre. Ainsi, les employés du gouvernement fédéral, des banques, des entreprises de télécommunication et de transport d’une province à une autre ne sont pas protégés.</p>
<p>Les cadres d’entreprise ne sont pas protégés complètement par les normes du travail! C’est d’ailleurs sur cet aspect que de nombreux litiges naissent, car des employeurs peuvent qualifier un employé comme un cadre afin de se soustraire à l’application de la loi alors qu’il n’en est pas réellement un.</p>
<p><strong>Vous n’êtes pas certain de savoir où vous vous placez dans cette situation? Pas de problème, un avocat en droit du travail pourra vous éclairer facilement, il suffit de remplir le formulaire de Soumissions Avocat!</strong></p>
<h2>Honoraires légaux : quel prix pour un avocat à Saint-Hyacinthe?</h2>
<p>Si vous avez déjà entendu dire que les honoraires des avocats sont dispendieux, on ne vous a pas menti. Toutefois, il y a plusieurs façons de magasiner votre avocat pour que les frais extrajudiciaires vous coutent un peu moins cher. Parmi ces façons, comparer les différents avocats et choisir le mode de rémunération qui vous convient sont des façons efficaces de réduire les coûts.</p>
<p><StaticImage alt="honoraires-legaux-frais-avocats-commission-saint-hyacinthe" src="../images/honoraires-legaux-frais-avocats-commission-saint-hyacinthe.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>À combien s’élève le taux horaire des avocats à Saint-Hyacinthe? </strong>Cela varie entre les 150$ et les 350$ de l’heure selon le type de pratique et le nombre d’années à pratiquer le droit. Il va sans dire que la réputation et le parcours de l’avocat en question jouent également sur son taux.</p>
<p>Vous n’êtes toutefois pas obligés d’engager un avocat qui travaille à l’heure! Si vous préférez un autre mode de rémunération et que votre cause le permet, vous pourriez offrir à votre avocat <strong>un pourcentage des dommages-intérêts</strong> obtenus en guise de salaire. Il faut évidemment que le montant anticipé soit assez intéressant pour qu’il accepte, mais c’est toujours une possibilité envisageable!</p>
<p>Les clients aux moyens financiers précaires qui ne se qualifient pas pour l’aide juridique n’ont pas à se priver des services d’un avocat pour autant! Plusieurs juristes sont prêts à prendre <strong>des ententes de paiement</strong> avec leurs clients leur permettant d’étaler leurs paiements sur une longue période de temps. Cela amortit le montant à payer et permet de donner un accès plus grand à la justice.</p>
<p>Ne soyez pas surpris si jamais un avocat vous demande une avance sur sa rémunération! La loi lui permet pareille démarche lorsque cela est nécessaire à l’accomplissement de ses tâches. Cette avance doit donc être justifiée, tel que le stipule le Code de déontologie des avocats!</p>
<h2>Soumissions Avocat est là pour trouver l’avocat qu’il vous faut à Saint-Hyacinthe!</h2>
<p>Bien qu’il soit noble de vouloir se défendre soi-même en cour, ce n’est définitivement pas la meilleure façon d’assurer un résultat positif. Le droit est un domaine trop complexe et unique pour qu’un étranger des normes juridiques s’y aventure avec succès. Toutefois, les avocats dans la région de Saint-Hyacinthe sont disposés à vous aider et à mettre fin à vos soucis légaux une fois pour toutes!</p>
<p><strong>Pour entrer en contact avec notre vaste réseau de partenaires avocats, il vous suffit de remplir le formulaire et de laisser le soin à Soumissions Avocat de vous trouver l’avocat qui convient à votre cause!</strong></p>
<p><strong>Pourquoi attendre davantage quand vous pourriez faire affaire avec les meilleurs avocats de la province simplement, rapidement et tout à fait gratuitement!</strong></p>
    </SeoPage>
)
export default ContentPage8
  